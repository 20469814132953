import React from 'react'
import { connect } from 'react-redux'
import Iframe from 'react-iframe'
import { CloseCircleTwoTone,ArrowRightOutlined } from '@ant-design/icons'
import { Row, Col, Modal, Button } from 'antd'
// import actions from 'redux/virtual/event/actions'

import './three.less'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
@connect(({ virtualSettings, virtualEvent }) => ({ virtualSettings, virtualEvent }))
class ThreeDimensionalModel extends React.Component {
  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isProductDemoVisible',
        value: false,
      },
    })
  }

  render() {
    const {
      virtualSettings: { isProductDemoVisible },
      virtualEvent: { currentProductDetails },
    } = this.props
    return (
      <>
        <Modal
          visible={isProductDemoVisible}
          style={{
            top: '0px',
            maxHeight: 'calc(100vh - 180px)',
          }}
          wrapClassName="downloadModal threeModal"
          width="90vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={
            <CloseCircleTwoTone 
              twoToneColor="#eb2f96"             
              style={{
                color: 'green',
                fontSize: '25px',
                width: '56px',
                height: '56px',
                left: '0px',
              }} 
            />}
          destroyOnClose
        >
          <Row>
            <Col xs={24} md={18} lg={18} xl={18}>
              <Iframe
                url={currentProductDetails.productDemoUrl}
                width="100%"
                height={`${currentProductDetails.productDemoHeight}px`}
                id="threedmodel"
                className="productModal"
                display="initial"
                position="relative"
                frameborder="0"
              />
            </Col>
            <Col xs={24} md={6} lg={6} xl={6} className="detailsboxContainer">
              <Row justify="space-around" align="middle" className="detailsbox">
                <Col span={24}>
                  <h2><b>{currentProductDetails.productName}</b></h2>
                  <p className="productsubtitle"><div dangerouslySetInnerHTML={{ __html: currentProductDetails.productDesc }} /></p>
                </Col>
              </Row>
              <Row justify="space-between" align="bottom">
                <Col span={24}>
                  <Button type="primary" className="ctabutton" onClick={() => {window.open(currentProductDetails.productCTALink, "blank")}} block>{currentProductDetails.productCTAText}<ArrowRightOutlined /></Button>
                </Col>
              </Row>
            </Col>
          </Row>

        </Modal>
        {/* <Row>
          <Col span={24}>
            
          </Col>
        </Row> */}
      </>
    )
  }
}

export default ThreeDimensionalModel
