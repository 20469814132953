import actions from './actions'

const initialState = {
  currentScreen: 'qanda',
  chat: [],
  qanda: {},
  polling: {},
  unreadChatCount: 0,
  unreadqndaCount: 0,
  currentReplyTo: {},
  assignedSessions: [],
  currentSessionDetails: {},
  isSessionFullScreen: false,
  notes: '',
  backstageVisible: false,
  backstageType: 'restream',
  backstageURL: '',
  currentBackstageStep: 0,
  directBackstageforSpeaker: true,
  isBackstageInitiallyLoaded: false,
  raiseHandRequests: {},
  isHandRaised: false,
  raiseHandLoading: false
}

export default function userReducer(state = initialState, action) {
  try {
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload }
      case actions.SET_MESSAGE:
        return {
          ...state,
          [action.payload.key]: state[action.payload.key].concat(action.payload.toAdd),
          [action.payload.countType]: action.payload.count,
        }
      case actions.SET_QUESTION:
        return {
          ...state,
          qanda: {
            ...state.qanda,
            [action.payload.message_id]: action.payload.toAdd,
          },
          unreadqndaCount: action.payload.count,
        }
      case actions.SET_POLLING:
        console.log("did we reach here?")
        console.log(action.payload)
        return {
          ...state,
          polling: {
            ...state.polling,
            [action.payload.message_id]: action.payload.toAdd,
          },
        }
      case actions.SET_RAISE_HAND:
        console.log("did we reach here?")
        console.log(action.payload)
        return {
          ...state,
          raiseHandRequests: {
            ...state.raiseHandRequests,
            [action.payload.user_id]: action.payload.toAdd,
          },
        }
      default:
        return state
    }
  } catch (err) {
    console.log(err)
    return state
  }
}
